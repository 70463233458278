//adding bootstrap core
// @import "~bootstrap/scss/bootstrap";

//adding custom theme styling
@import "./assets/scss/main/";
// @import "./assets/scss/main/bootstrap/variables";

//importing default font start
//bold black medium regular 
@font-face{
    font-family: 'Poppins-Bold';
    src:local('Poppins-Bold'), url(/assets/poppins/Poppins-Bold.otf) format('opentype');
}

@font-face{
    font-family: 'Poppins-Black';
    src:local('Poppins-Black'), url(/assets/poppins/Poppins-Black.otf) format('opentype');
}

@font-face{
    font-family: 'Poppins-Medium';
    src:local('Poppins-Medium'), url(/assets/poppins/Poppins-Medium.otf) format('opentype');
}

@font-face{
    font-family: 'Poppins-Regular';
    src:local('Poppins-Regular'), url(/assets/poppins/Poppins-Regular.otf) format('opentype');
}
@font-face{
    font-family: 'Poppins-SemiBold';
    src:local('Poppins-SemiBold'), url(/assets/poppins/Poppins-SemiBold.otf) format('opentype');
}
@font-face{
    font-family: 'Poppins-Light';
    src:local('Poppins-Light'), url(/assets/poppins/Poppins-Light.otf) format('opentype');
}
//importing default font end

// body {
//     font-family: 'Poppins-Regular';
//  }

 * {
    // font-family: 'Poppins-Light';
    font-size: 0.90rem;
    line-height: 22px
}

.collapse-toggle{
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    width: 35px;
    height: 35px;
    left: 21%;
    z-index: 999999;
    color: #005B97;
    top: 20px;
    background: #FFFFFF;
    box-shadow: 3px 4px 3px rgb(0 0 0 / 25%);
    border-radius: 12px;
   }
  
//    #sidebar.active {
//      .collapse-toggle {
//        left: 80% !important;
//      }
//    }
  
   .collapse-toggle {
    .fa-solid{
      position: absolute;
      top: 5px;
      left: 40%;
    }
   }


//navbar design start
.custom-navbar > span{
        color: black;
        // font-family: 'Poppins-Regular';
}

.custom-navbar{
    padding: 0.3rem 1rem 0.3rem 2.5rem;
    border: 1px solid #CCCCCC;
    border-radius: 20px;
}
.custom-navbar > div{
    top: 0;
    // padding: 0.3rem;
    // background: #000000;
    left: 0;
    color: white !important;
    border-radius: 30px;
}
//navbar design end


//main content start
.time-alert{
    background-color: #3375BD;
    color: #FFFFFF;
    border-radius: 5px;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}

// .poppins-normal{
//     font-family: 'Poppins-Regular';
// }

.timing {
    background: #F9F9F9;
    box-shadow: inset -10px -10px 20px rgba(0, 61, 89, 0.05), inset 10px 10px 20px rgba(0, 61, 89, 0.05);
    // border-radius: 20px;
}

.f-20{
    font-size: 2rem;
}
.f-25{
    font-size: 2.4rem;
}

.f-18{
    font-size: 1.125rem;
}
.f-16{
    font-size: 1rem;
}

.f-14{
    font-size: 0.875rem !important;
}
.f-15{
    font-size: 0.9375rem !important;
}
.f-xsmal{
    font-size: 0.90rem !important;
}

.f-12{
    font-size: 1.2rem !important;
}

.f-12-small{
    font-size: 0.75rem !important;
}

.f-11{
    font-size: 0.6875rem !important;
}

.f-24{
    font-size: 1.5rem !important;
}

.f-22{
    font-size: 1.375rem;
}

.f-45{
    font-size: 2.8125rem;
}
.f-28{
    font-size: 1.75rem;
}

.f-h5{
    font-size: 1.25rem;
}

.stats-shadow {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
}

.bg-greyish{
    background-color: #FAFAFA;
}

.bg-blue{
    background-color: #005B97 !important;
}

.stats-banner{
    background: url("../public/img/masjid/masjid_banner.png");
    background-position: bottom;
    background-size: cover;
    > .layer{
        content: "";
        // background: rgba(45, 88, 35, 0.7);
        background-color: #005B97;
        // position: absolute;
        opacity: 0.8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}

.prayers-table-header{
    &.white{
        background: white;    
    }
    background: #000000;
    border-radius: 20px 20px 0px 0px;
}

.prayers-table-header>tr>th:first-child {
    border-radius: 10px 0 0 0px;
}
.prayers-table-header>tr>th {
    font-size: 14px;
}

.prayers-table-header>tr>th:last-child {
    border-radius: 0 10px 0px 0;
}

.prayer-table-body > tr > td{
    border: 1px solid rgba(235, 235, 235, 1);
    border-left: none;
    border-right: none;
}

.category-border-right{
    border: 1px solid;
    border-image-source: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 54.17%, rgba(0, 0, 0, 0) 100%);
}

.category-point-outer-circle{
    border: 1px solid rgba(196, 196, 196, 1);
    padding: 0.3rem;
    border-radius: 50px;
    > div{
        padding: 0.3rem;
        border-radius: 50px;
        &.blue{
            background: #0187DE;
            border: 1px solid rgba(1, 135, 222, 1);
        }&.light-blue{
            background: #75C8FF;
            border: 1px solid rgba(117, 200, 255, 1);
        }&.green{
            background: #6CEB97;
            border: 1px solid rgba(108, 235, 151, 1);
        }&.yellow{
            background: #EAC870;
            border: 1px solid rgba(234, 200, 112, 1);
        }&.red{
            background: #FF847C;
            border: 1px solid rgba(255, 132, 124, 1);
        }
    }
}

.category-right-border {
    border-right: 1px solid lightgray;
    // background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 54.17%, rgba(0, 0, 0, 0) 100%);
}

@media (max-width: 992px){
    .category-right-border {
        border-right: none !important;
    }
}


.table{
    margin-bottom: 0 !important;
}

// .row > [class*='col-'] {
//     display: flex;
//     flex-direction: column;
// }

.nested-nav > .nav-item > .nav-link{
    color: white !important;
}
.navbar-nav > .nav-item > .nav-link{
    color: white !important;
}
//main content end

.nav-item::marker{
    color: white;
    display: none;
}

.w-15{
    width: 10%;
}
.w-12{
    width: 12%;
}

.w-100{
    width: 100%;
}

.d-block{
    display: block;
}

.d-none{
    display: none;
}

.cursor-pointer{
    cursor: pointer;
}

.cursor-not-allowed{
    cursor: not-allowed !important;
}

.f-80{
    font-size: 0.80rem;
}

.hoverLink{
    color: #005B97;
    &:hover{
        color: #FF6A2B;
    }
}

.loader-wrapper {
    position: absolute;
    z-index: 999999;
    /* top: 50%; */
    /* left: 50%; */
    background: white;
    opacity: 1;
    /* color: black; */
    width: 100%;
    height: 100%;
  }
  
  .main-loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }

.nav-item{
    list-style: none;
}

.nav-collapse{
    transform: translateX(-17.125rem);
}

.toggle-navbar-button {
    box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.75);
    background: #015B97;
    padding: 1rem;
    border-radius: 10px;
    // left: 12%;
    z-index: 99999999;
    top: 2%;
}

.form-control {
    appearance: auto !important;
}

.btn-action{
    border: 1px solid #d2d6da;
    padding: 0.5rem;
    border-radius: 10px;
}

// .card{
//     > .card-body{
//         font-family: "Poppins-Regular";   
//     }
// }

.height-240{
    height: 240px;
}

.border-bottom{
    border-bottom: 1px solid #015B97 !important;
}

//button loader start
  
  .button--loading .button__text {
    visibility: hidden;
    opacity: 0;
  }
  
  .button--loading::after {
    content: "";
    position: absolute;
    width:  30px;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #00A3A7;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
//button loader end

.pointer-event-none{
    pointer-events: none;
}

.delete-icon:hover{
    color: var(--#{$prefix}link-hover-color)
}

.text-no-wrap{
    white-space: nowrap;
}

.icon-button{
    border: 1px solid;
    padding: 0.3rem;
    border-radius: 10px;
}

.btn-orange{
    background-color: $orange !important;
}

.height-100-vh{
    height: 100vh;
}

// .poppins-semibold{
//     font-family: 'Poppins-SemiBold';
// }
// .poppins-medium{
//     font-family: 'Poppins-Medium';
// }

.text-light-blue{
    color: $light-blue;
}

.nested-nav li {
    position: relative;
  }
  .nested-nav li a::before {
    
    content: " ";
    position: absolute;
    width: 2px;
    background-color: #99BDD5;
    border: 1px solid #99BDD5;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 7px;
    top: -15px;
    bottom: 15px;
    left: -3px;
  }
  
//   .nested-nav li a.active::before{
//     top: -22px;
//   }
//   body > ul > li:first-child::before {top: 12px;}
//   ul li:not(:first-child):last-child::before {display: none;}
//   ul li:only-child::before {
//     display: list-item;
//     content: " ";
//     position: absolute;
//     width: 1px;
//     background-color: #000;
//     top: 5px;
//     bottom: 7px;
//     height: 7px;
//     left: -10px;
//   }
  .nested-nav li::after {
    content: " ";
    position: absolute;
    border: 1px solid #99BDD5;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 7px;
    left: -2px;
    width: 10px;
    height: 1px;
    background-color: #99BDD5;
    top: 20px;
  }


  .short-menu {
      background: $argon-background-color;
      box-shadow: 0px 8px 21px rgba(0, 65, 107, 0.3);
      border-radius: 50%;
      width: 40px;
      height: 40px;
  }

hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #5c737f;
    padding: 0;
    margin: 16px 10px;
}

.card{
    border-radius: 0;
}

.form-check-input {
    background-color: $form-range-thumb-disabled-bg !important;
}

.anchor{
    > a{
        color: $ma-primary;
    }
    
    text-decoration: underline;
    :hover{
        color: $light-blue;
    }
}

.box-shadow-none{
    box-shadow: none;
}

.border-none{
    border: none !important;
}

// css for setting quill editor's height
.ql-container {
    min-height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}

.txt-color{
    height: 36px !important;
    width: 100% !important;
    padding: 0.5rem 0.75rem !important;
}

.form-select {
    padding: 0.5rem 2rem 0.5rem 0.75rem !important;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}

th{
    color: #344767;
}

.border-radius-none{
    border-radius: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
    color: #fff;
    background-color: $primary !important;
    border-color: $primary !important;
}

.dropdown-toggle::after {
    margin-left: 1em;
}

.card-actions{
    padding: 0.5rem;
    border: 1px solid;
    border-radius: 5px;
}

.text-balance{
    text-wrap: balance
}

.image-icon {
    height: 80px;
    width: 80px;
    &.small{
        height: 50px !important;
        width: 50px !important;
    }
    &.square{
        border-radius: 0;
        margin-right: 0;
    }
    &.store-img{
        // padding: 1rem;
        background-color: white;
    }
    pointer-events: none;
    object-fit: cover;
    border-radius: 50%;
    // margin-right: 12px;
    padding: 0.2rem;
    border: 1px solid lightgrey;
}

.disabled{
    cursor: not-allowed;
    opacity: 0.5;
}

.border-one{
    border: 1px solid #d2d6da !important;
}

.shadow-none{
    box-shadow: none;
}

.form-control:disabled{
    cursor: not-allowed;
}
.confirmation-dialog{
    .modal-content{
        border-radius: 0;
    }
}

.modal-title{
    font-weight: 600 !important;
}

.dialog-height{
    min-height: auto;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.store-card:hover{
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1)
}

.organization-logo{
    max-width: 100%;
    max-height: 120px;
}

.donation-widget-logo{
    max-height: 60px;
    max-width: 310px;
}

.main-page-logo{
    max-height: 73px;
    max-width: 150px;
}

.text-underline{
    text-decoration: underline;
}

.emailReceiptTemplate{
    > p{
        margin-bottom: 0;
    }
}

.input-group-text {
    padding: 0.75rem 0.75rem !important;
}

input:disabled {
    cursor: not-allowed !important;
}

.error-field{
    box-shadow: #c700285e 0px 2px 8px 0px;
    border: 1px solid red;
}

.required_field{
    padding: 0;
    border: none;
    &:focus{
        box-shadow: none;
    }
}

// .btn{
//     margin-bottom: 0 !important;
// }

p{
    margin-bottom: 0rem !important;
}

.text-black{
    color: black !important;
}

.border-radius-10{
    border-radius: 10px;
}

.text-bold-800{
    font-weight: 800;
}

.connection-pulse {
	// background: #00c164;
	border-radius: 50%;
	height: calc(0.5vh + 0.5vw);
	width: calc(0.5vh + 0.5vw);

	box-shadow: 0 0 0 0 #00c164;
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

[class*="-MultiValueRemove"] {
    color: rgb(0, 135, 90) !important;
    &:hover{
        background-color: rgb(0, 135, 90) !important;
        color: white !important;
    }
}

[class*="-MultiValueGeneric"] {
    color: rgb(0, 135, 90) !important;
}

[class*="-multiValue"] {
    background-color: rgba(0, 135, 90, 0.1) !important;
}

.burger-menu {
    border: 1px solid;
    border-radius: 5px;
    padding: .5rem;
}